import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { GET_AI_CONFIG_SCHEMA } from '../../../../graphql/query';
import { Divider, FormControl, FormControlLabel, InputLabel, LinearProgress, MenuItem, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import { JSONSchema7 } from 'json-schema';
import ParamEditor from '../../../../components/pixie/ParamEditor';
import { FlowNodeName } from '../FlowNodeName';
import { TFlowPluginType, TPluginInfo } from '../../../../../generated/gql/graphql';
import { FlowNodeData } from '../../../../types/GraphNode';
import { useEditorStore } from '../../../../hooks/EditorState';
import { useShallow } from 'zustand/react/shallow';


export function AppSettingsPanel(): React.ReactElement {
  const [aiConfigSchema, setAiConfigSchema] = useState<{ [modelName: string]: JSONSchema7 } | null>(null);
  useQuery(GET_AI_CONFIG_SCHEMA, {
    onCompleted(data) {
      setAiConfigSchema(data.getAiConfigSchema);
    },
    // https://github.com/apollographql/apollo-client/issues/11151
    notifyOnNetworkStatusChange: true,
  });

  const startNodeId = useEditorStore(state => state.app.startNodeId);
  const setStartNodeId = useEditorStore(state => state.actions.setStartNodeId);
  const nodeIds = useEditorStore(state => Object.keys(state.app.graph.nodesData));
  const aiConfig = useEditorStore(state => state.app.aiConfig);
  const setAiConfig = useEditorStore(state => state.actions.setAiConfig);
  const [requireSignin, setRequireSignin] = useEditorStore(useShallow(state => [
    state.app.signinRequired,
    state.actions.setSigninRequired,
  ]))
  // TODO hack, should get defaults from server.
  const aiConfigModelName = aiConfig?.ai_model_name || 'chatgpt';

  return <Stack spacing={2}>
    <Typography variant='subtitle1'><b>Starting point</b></Typography>
    {nodeIds.length == 0
      ? <Typography>Add a function to configure starting point.</Typography>
      : <Select
        value={startNodeId || nodeIds[0]}
        onChange={e => setStartNodeId(e.target.value)}
      >
        {nodeIds.map(
          id => <MenuItem key={id} value={id}>
            <FlowNodeName nodeId={id} variant='description' />
          </MenuItem>
        )}
      </Select>
    }

    <Divider />

    <Typography variant='subtitle1'><b>Authentication</b></Typography>
    <FormControlLabel
      control={<Switch checked={requireSignin} onChange={(_, checked) => setRequireSignin(checked)} />}
      label="Require user to signin"
    />

    <Divider />
    <Typography variant='subtitle1'><b>AI settings</b></Typography>
    {aiConfigSchema
      ? <Stack spacing={2}>
        <FormControl>
          <InputLabel id="modelName">model type</InputLabel>
          <Select
            label="model type"
            labelId="modelName"
            value={aiConfigModelName}
            onChange={e => {
              const newConfig = structuredClone(aiConfig || {});
              newConfig.ai_model_name = e.target.value;
              setAiConfig(newConfig);
            }}
          >
            {Object.entries(aiConfigSchema).map(([modelName, _]) => {
              return <MenuItem key={modelName} value={modelName}>{modelName}</MenuItem>
            })}
          </Select >
        </FormControl>
        {aiConfigSchema[aiConfigModelName] && <ParamEditor
          dynamicDisabled
          schema={aiConfigSchema[aiConfigModelName]}
          value={aiConfig?.ai_model_config}
          onChange={(value) => {
            const newConfig = structuredClone(aiConfig || {});
            // TODO this is needed because of the hack above - otherwise the updated value will miss ai_model_name
            // and if server's default and client's default are different, the ai_model_config could be wrongly deserialized
            newConfig.ai_model_name = aiConfigModelName;
            newConfig.ai_model_config = structuredClone(value);
            setAiConfig(newConfig);
          }}
          resetTrigger={aiConfigSchema}
        />
        }
      </Stack>
      : <LinearProgress />}
  </Stack>
}
