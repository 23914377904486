import { alpha, AppBar, Box, Button, Card, Grid, LinearProgress, Slide, Stack, Toolbar, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { useHistory } from 'react-router-dom';

import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { useMutation, useQuery } from '@apollo/client';
import { AppContext } from '../../contexts/AppContext';
import { GET_PAYMENT_DETAILS, GET_SITE, LIST_FLOWS } from '../../graphql/query';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { getFlowEditorPathV2 } from '../../components/pixie/common';
import AuthButton, { SignInRequired } from '../../components/Auth';
import PixieIcon from '../../components/pixie/CompanyIcon';
import { useIdentityStore } from '../../hooks/IdentityState';
import { START_BILLING_PORTAL_SESSION, START_CHECKOUT } from '../../graphql/mutation';
import { PaymentStatus, SubscriptionPlan } from '../../../generated/gql/graphql';

export default function WorkspacePage(): React.ReactElement {
  const height = use100vh();
  const theme = useTheme();
  const { setError } = useContext(AppContext);
  const routerHistory = useHistory();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [menuHover, setMenuHover] = useState(false);
  const siteId = useIdentityStore(state => state.workspaceId);

  const { data: siteData } = useQuery(GET_SITE, {
    variables: { siteId },
    onError: setError,
    skip: !siteId,
  })
  const workspaceName = siteData?.site.title || 'Your workspace';

  const { loading, data } = useQuery(LIST_FLOWS, {
    variables: { siteId },
    onError: setError,
    skip: !siteId,
  });
  const flows = data?.alistFlows || [];

  const { data: paymentData } = useQuery(GET_PAYMENT_DETAILS, {
    variables: { siteId },
    onError: setError,
    skip: !siteId,
  });

  const [paymentActionInProgress, setPaymentActionInProgress] = useState(false);

  const [startCheckout] = useMutation(START_CHECKOUT);
  const [viewBillingPortal] = useMutation(START_BILLING_PORTAL_SESSION);

  const hasActiveSubscription = (
    paymentData?.agetPaymentDetails?.subscriptionPlan !== SubscriptionPlan.Free
    && paymentData?.agetPaymentDetails?.status === PaymentStatus.Active
  );

  useEffect(() => {
    document.title = `Workspace: ${workspaceName} | GoPixie.ai`
  }, [workspaceName])


  return <SignInRequired signInDialog message='Please sign in.'>
    <Stack
      height={height || '100vh'}
      width='100vw'
      overflow='hidden'
      display='flex'
    >
      <AppBar position='static' elevation={0} color='inherit' onMouseEnter={() => setMenuHover(false)}>
        <Toolbar disableGutters sx={{
          p: 1,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}>
          <Stack direction='row' width='100%' spacing={2} display='flex' justifyContent='space-between' alignItems='center' pr={2}>
            <Stack direction='row' spacing={3} display='flex' alignItems='center'>
              <Button
                size='large'
                sx={{
                  borderRadius: 5,
                  fontSize: 32,
                  p: 2,
                  color: sidebarOpen ? theme.palette.secondary.main : 'grey.300',
                  '&:hover': {
                    backgroundColor: 'secondary.light',
                    color: 'secondary.contrastText',
                  }
                }}
                onClick={() => setSidebarOpen(o => !o)}
              >
                <MenuRoundedIcon fontSize='inherit' />
              </Button>
              <PixieIcon onClick={() => routerHistory.push('/')} />
            </Stack>
            <AuthButton onSignout={() => routerHistory.push('/')} />
          </Stack>
        </Toolbar>
      </AppBar>
      <Stack direction='row' width='100%' overflow='hidden' flexGrow={1} display='flex' position='relative'>
        <Slide
          in={sidebarOpen || menuHover}
          direction='right'
          mountOnEnter unmountOnExit
        >
          <Box sx={{
            width: { xs: '100vw', md: '25vw', xl: '20vw' },
            position: "absolute",
            left: 0,
            top: 0,
            height: "100%",
            borderRight: `1px solid ${theme.palette.divider}`,
            boxShadow: '5px 0 10px -10px #000',
            background: theme.palette.background.paper,
            overflow: 'auto',
            zIndex: 1002,
          }}>
            Workspaces
          </Box>
        </Slide>

        <Box
          onMouseOver={() => setMenuHover(true)}
          sx={{
            background: theme.palette.background.paper,
            borderRight: `1px solid ${theme.palette.divider}`,
            boxShadow: '5px 0 10px -10px #000',
            zIndex: 1001,
          }}
        >
          <Stack p={1} spacing={1} height='100%'>
            <Button
              size='large'
              sx={{
                borderRadius: 5,
                fontSize: 32,
                p: 2,
                color: 'grey.300',
              }}
            >
              <GroupsRoundedIcon fontSize='large' />
            </Button>
          </Stack>
        </Box>

        <Stack
          spacing={{ xs: 2, lg: 4 }}
          p={{ xs: 2, md: 4, lg: 8 }}
          height='100%'
          flexGrow={1}
          sx={{ background: theme.palette.grey[50], overflow: 'auto' }}
          onMouseEnter={() => setMenuHover(false)}
        >
          <Stack direction='row' spacing={2} alignItems='center'>
            <Typography variant='h5'><b>{workspaceName}</b></Typography>
            <Tooltip
              title={<Typography variant='h6'>{hasActiveSubscription ? "View billing" : "Upgrade plan"}</Typography>}
              placement='right'
              arrow
            >
              <Button
                variant='outlined'
                disabled={paymentActionInProgress}
                color='primary'
                sx={{ borderRadius: 4, textTransform: 'none' }}
                onClick={() => {
                  setPaymentActionInProgress(true);
                  const future = hasActiveSubscription
                    ? viewBillingPortal({
                      variables: { siteId }
                    }).then(res => {
                      window.open(res.data.astartBillingPortalSession, "_self")
                    })
                    : startCheckout({
                      variables: {
                        siteId,
                        subscriptionPlan: SubscriptionPlan.Starter,
                      }
                    }).then(res => {
                      window.open(res.data.astartCheckout, "_self")
                    });
                  future.catch(setError).finally(() => setPaymentActionInProgress(false));
                }}
              >
                {paymentActionInProgress
                  ? <Typography variant='h6'>processing</Typography>
                  : <Typography variant='h6'>
                    {paymentData?.agetPaymentDetails?.subscriptionPlan?.toLocaleLowerCase() || 'free'} plan
                  </Typography>
                }
              </Button>
            </Tooltip>
          </Stack>
          {loading
            ? <LinearProgress />
            : <Grid container m={0} spacing={2} width='100%' alignItems='stretch'>
              {flows.map(
                ({ id, name }) => <Grid item key={id} xs={12} md={4} lg={3} xl={2}>
                  <Card
                    variant='outlined'
                    onClick={() => routerHistory.push(getFlowEditorPathV2(id))}
                    sx={{
                      p: { xs: 1, md: 2, lg: 4 },
                      borderRadius: { xs: 1, md: 2, lg: 3 },
                      border: `2px solid ${theme.palette.divider}`,
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      justifyContent: 'center',
                      '&:hover': {
                        boxShadow: `3px 10px 10px ${theme.palette.grey[500]}`,
                        background: alpha(theme.palette.secondary.dark, 0.1),
                        border: `2px solid ${theme.palette.secondary.dark}`,
                      }
                    }}
                  >
                    <Typography variant='h6' textAlign='center'>{name}</Typography>
                  </Card>
                </Grid>
              )}
              <Grid item xs={12} md={4} lg={3} xl={2}>
                <Card
                  variant='outlined'
                  onClick={() => routerHistory.push(getFlowEditorPathV2())}
                  sx={{
                    p: { xs: 1, md: 2, lg: 4 },
                    borderRadius: { xs: 1, md: 2, lg: 3 },
                    border: `2px solid ${theme.palette.divider}`,
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    background: alpha(theme.palette.secondary.dark, 0.5),
                    color: theme.palette.secondary.contrastText,
                    '&:hover': {
                      boxShadow: `3px 10px 10px ${theme.palette.grey[500]}`,
                      background: theme.palette.secondary.dark,
                    }
                  }}
                >
                  <Typography variant='h2' textAlign='center' display='flex' justifyContent='center'><AddRoundedIcon fontSize='inherit' /></Typography>
                </Card>
              </Grid>
            </Grid>
          }
        </Stack>
      </Stack>
    </Stack>
  </SignInRequired>
}
