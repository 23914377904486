import { Button, Icon, IconButton, Stack, Typography, useTheme } from '@mui/material';
import React, {
} from 'react';
import { TPluginInfo } from '../../../../generated/gql/graphql';
import { FlowNodeData } from '../../../types/GraphNode';
import { JSONSchema7 } from 'json-schema';
import ParamEditor from '../../../components/pixie/ParamEditor';
import { DataReferences } from '../../../types/DynamicValueTypes';
import { useEditorStore, useTypeInfoForNode } from '../../../hooks/EditorState';
import { useApolloClient } from '@apollo/client';
import { NameChangeV2 } from './NameChange';
import { FlowNodeName } from './FlowNodeName';
import { MixedParamInputField } from '../../../components/pixie/MixedParamInputField';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

// NOTE only plugin Id with both info and data present would be included
function createReferences(
  pluginInfoById: { [id: string]: TPluginInfo },
  nodeDataById: { [id: string]: FlowNodeData }
): DataReferences {
  const result = {};

  // Iterate over the keys of the first object
  Object.keys(pluginInfoById).forEach(key => {
    if (nodeDataById.hasOwnProperty(key)) {
      result[key] = { info: pluginInfoById[key], data: nodeDataById[key] };
    }
  });

  return result;
};

function ConfigurationPanel(props: {
  nodeId: string,
  dynamicDisabled?: boolean,
  paramsOnly?: boolean,
  p?: number,
}): React.ReactElement {
  const theme = useTheme();
  // We have expectation that nodeId exists
  const nodeData = useEditorStore(state => state.app.graph.nodesData[props.nodeId]);
  const updateNodeData = useEditorStore(state => state.actions.graph.updateNodeData);

  const typeInfo = useTypeInfoForNode(props.nodeId);
  const dynamicPluginType = nodeData?.pluginType?.dynamic;
  const pluginConstructInfo = useEditorStore(state => dynamicPluginType ? state.types.construct[dynamicPluginType.constructType] : undefined);

  const refreshTypeInfo = useEditorStore(state => state.graphql.loadDynamicType);
  const client = useApolloClient();

  if (!nodeData || !typeInfo) {
    return <Stack>
      <Typography variant='h6'>Node not found</Typography>
    </Stack>;
  }

  // const references = useMemo(
  //   () => {
  //     logDebug('reference changed');
  //     return createReferences(props.pluginInfoById, props.nodeDataById);
  //   },
  //   [props.pluginInfoById, props.nodeDataById],
  // )
  return <Stack spacing={2} p={props.p === undefined ? 2 : props.p}>
    {!props.paramsOnly && <>
      <NameChangeV2
        value={nodeData.displayName || ''}
        onChange={name => updateNodeData(nodeData.id, { displayName: name })}
        display={<FlowNodeName nodeId={nodeData.id} variant='description' />}
      />
      <Stack spacing={1}>
        <Stack direction='row' display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='subtitle2'>In-Progress Status Message</Typography>
          {nodeData.inProgressMessage
            ? <IconButton color='error' onClick={() => updateNodeData(props.nodeId, { inProgressMessage: undefined })}><RemoveRoundedIcon /></IconButton>
            : <IconButton color='success' onClick={() => updateNodeData(props.nodeId, { inProgressMessage: [] })}><AddRoundedIcon /></IconButton>
          }
        </Stack>
        {nodeData.inProgressMessage && <MixedParamInputField
          initialFocus={false}
          dynamicValue={nodeData.inProgressMessage}
          onChange={(v) => {
            if (Array.isArray(v)) {
              updateNodeData(props.nodeId, { inProgressMessage: v })
            }
            else {
              updateNodeData(props.nodeId, { inProgressMessage: [v] })
            }
          }}
        />}
      </Stack>

      {pluginConstructInfo && <ParamEditor
        resetTrigger={props.nodeId} //we have the assumption the id will not change for same node
        dynamicDisabled
        schema={pluginConstructInfo.parameterSchema as JSONSchema7}
        // rootName='Customization'
        value={dynamicPluginType!.param}
        onChange={(v, _) => updateNodeData(
          props.nodeId,
          {
            // NOTE this triggers construct info refetch on every onChange call
            pluginType: {
              dynamic: {
                constructType: dynamicPluginType!.constructType,
                param: v,
              }
            }
          }
        )}
      />}
    </>}
    {typeInfo?.dynamicTypeInfoOutdated
      && <Stack spacing={1}>
        <Button variant='contained' sx={{ textTransform: 'none' }} onClick={() => refreshTypeInfo(client, props.nodeId)}>
          <Typography variant='h6'>Refresh Parameters</Typography>
        </Button>
        <Typography textAlign='center' variant='body2' sx={{ color: theme.palette.text.secondary }}>
          The list of parameters might have changed due to your changes.
        </Typography>
      </Stack>
    }
    {typeInfo?.pluginInfo && <ParamEditor
      resetTrigger={props.nodeId} // we have the assumption the id will not change for same node
      schema={typeInfo?.pluginInfo.parameterSchema as JSONSchema7}
      value={nodeData.params}
      dynamicValue={nodeData.dynamicParams}
      dynamicDisabled={props.dynamicDisabled}
      onChange={(v, dv) => updateNodeData(
        props.nodeId,
        {
          params: v,
          dynamicParams: dv,
        }
      )}
      disableOverlay={typeInfo?.dynamicTypeInfoOutdated
        && <Typography variant='h5'><b>Please refresh parameters.</b></Typography>
      }
    />}
  </Stack>;
}

export default ConfigurationPanel;
