import React, { useEffect, useMemo } from 'react';
import {
  Node,
  NodeProps,
  XYPosition,
  useUpdateNodeInternals,
} from 'reactflow';
import {
  Box,
  Stack,
  Typography,
  lighten,
  useTheme,
} from '@mui/material';
import {
  TFlowPluginV2,
} from '../../../../generated/gql/graphql';
import {
  FlowNodeData,
  FlowNodeType,
} from '../../../types/GraphNode';
import {
  ConnectHandle, ConnectHandleGroup,
} from './ConnectHandle';
import { FlowNodeName } from './FlowNodeName';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useEditorStore, useTypeInfoForNode } from '../../../hooks/EditorState';

export function toFlowNode(nodeData: TFlowPluginV2, position?: XYPosition): Node<FlowNodeData, FlowNodeType> {
  return {
    id: nodeData.id,
    position: position || { x: 0, y: 0 },
    type: "flowNode",
    data: nodeData,
  }
}


export default function FlowNode({ id, data, selected }: NodeProps<FlowNodeData>): React.ReactElement {
  const typeInfo = useTypeInfoForNode(id);
  const theme = useTheme();
  // const { tunings, toggleTuning } = useContext(TuningContext);
  const updateNodeInternals = useUpdateNodeInternals();
  const startNodeId = useEditorStore(state => state.app.startNodeId);
  const selectedNodeOrEdge = useEditorStore(state => state.app.graph.selected);
  const highlightedHandles = useEditorStore(state => {
    if (selectedNodeOrEdge?.type === 'edge') {
      if (state.app.graph.partialEdges[selectedNodeOrEdge.id].source !== id) return [];
      const sourceHandle = state.app.graph.partialEdges[selectedNodeOrEdge.id]?.sourceHandle;
      if (sourceHandle) return [sourceHandle];
      else return [];
    }
    else if (selectedNodeOrEdge?.type === 'node') {
      return Object.values(state.app.graph.partialEdges)
        .filter(e => e.source === id && e.target === selectedNodeOrEdge.id && e.sourceHandle)
        .map(e => e.sourceHandle!)
    }
    else return [];
  });

  // need to explicitly let reactflow rerender edges after handle update
  useEffect(() => {
    updateNodeInternals(id);
  }, [typeInfo?.pluginInfo?.allowedBranches, id]);

  const boxStyle = useMemo(() => {
    return selected
      ? {
        backgroundColor: lighten(theme.palette.primary.light, 0.8),
        border: `4px solid ${theme.palette.secondary.dark}`,
        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.5)',
      }
      : {
        border: '2px solid black',
        backgroundColor: theme.palette.background.paper,
      };
  }, [selected]);

  return <Box position='relative' p={2} borderRadius={4} sx={boxStyle} width='400px'>
    {id === startNodeId && <Stack direction='row' display='flex' alignItems='center' sx={{
      position: 'absolute',
      top: '50%',
      left: -100,
      transform: 'translateY(-50%)',
    }}>
      <Typography variant='h6' color='secondary'><b>START</b></Typography>
      <ArrowRightIcon color='secondary' fontSize='large' />
    </Stack>
    }
    <FlowNodeName variant='title' nodeId={id} />
    {/* <Typography><InlineCode>{JSON.stringify(data.params, null, 2)}</InlineCode></Typography>
      <Typography><InlineCode>{JSON.stringify(data.dynamicParams, null, 2)}</InlineCode></Typography> */}

    <ConnectHandle nodeId={id} handleId='prev' />
    <ConnectHandleGroup nodeId={id} handleIds={typeInfo?.pluginInfo?.allowedBranches || []} highlighted={highlightedHandles} />
  </Box>
}
