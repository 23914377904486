import { Button, Checkbox, FormControlLabel, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { useMutation } from '@apollo/client';
import { SUBMIT_CONTACT_FORM } from '../../graphql/mutation';
import { useIdentityStore } from '../../hooks/IdentityState';

export default function ContactForm(props: {
  siteId: string,
  onSubmit?: () => void,
}) {
  const user = useIdentityStore(state => state.user);
  const [email, setEmail] = useState<string>(user?.username || '');
  const [hasSubscribed, setHasSubscribed] = useState(true);
  const [message, setMessage] = useState<string>('');
  const [submitContactForm] = useMutation(SUBMIT_CONTACT_FORM);
  const [isValid, setIsValid] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const { setError, setSuccessMessage } = useContext(AppContext);

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);

    // Perform additional validation using a regular expression
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    setIsValid(emailPattern.test(inputEmail));
  };

  function handleSubmit() {
    if (email.length == 0 || !isValid) setIsValid(false);
    else {
      setInProgress(true);
      submitContactForm({ variables: { siteId: props.siteId, email, subscribeForUpdate: hasSubscribed, comment: message } })
        .then(_ => {
          setSuccessMessage("successfully submitted.");
          props.onSubmit?.();
        })
        .catch(e => setError(e))
        .finally(() => setInProgress(false));
    }
  }
  return inProgress
    ? <LinearProgress />
    : <Stack spacing={2} width='80vw'>
      <Typography variant='h4' pb={3}><b>Get in touch</b></Typography>
      <TextField
        type='email'
        variant='outlined'
        label='your email'
        value={email}
        onChange={handleEmailChange}
        error={!isValid}
        helperText={!isValid ? 'Please enter a valid email address.' : ''}
        onKeyDown={e => { if (e.key == 'Enter') handleSubmit() }}
      />
      <TextField
        label='message (optional)'
        variant='outlined'
        value={message}
        onChange={e => setMessage(e.target.value)}
        placeholder='write your message here.'
        multiline
        minRows={4}
      />
      <FormControlLabel
        label="subscribe for updates"
        control={<Checkbox checked={hasSubscribed} onChange={(_, checked) => setHasSubscribed(checked)} />}
      />
      <Button variant='contained' size='large' sx={{ textTransform: 'none' }} onClick={handleSubmit}>connect with us</Button>
    </Stack >
}
