import {
  Button, IconButton, LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField, Typography
} from '@mui/material';
import React, { useContext, useState } from 'react';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { SecretType } from '../../../../../generated/gql/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_WHATSAPP_WEBHOOK } from '../../../../graphql/mutation';
import { LIST_SECRETS, LIST_WHATSAPP_WEBHOOKS } from '../../../../graphql/query';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { AppContext } from '../../../../contexts/AppContext';
import { getWsUrl } from '../../../../utils/getWsUrl';
import { useEditorStore } from '../../../../hooks/EditorState';


export function LaunchChannelsPanel(props: {
  siteId: string;
}): React.ReactElement {
  const appId = useEditorStore(state => state.app.id);

  const [linkCopied, setLinkCopied] = useState(false);
  const [codeCopied, setCodeCopied] = useState(false);
  const host = window.location.protocol + "//" + window.location.host;
  const sharingLink = `${host}/v2/app/${appId}`;
  // TODO either serve the file via backend server, or get the CDN path in a more reliable way
  const embedUrl = "https://storage.googleapis.com/butterfly-static/build/embed-bundle.js";
  const embedCode = `<script async src="${embedUrl}"></script>\n<go-pixie-chat appid="${appId}"></go-pixie-chat>`;

  const [addingWhatsApp, setAddingWhatsApp] = useState(false);
  const [whatsAppAccountId, setWhatAppAccountId] = useState<string>('');
  const { setError } = useContext(AppContext);
  const { loading, data: secrets } = useQuery(LIST_SECRETS, {
    variables: { siteId: props.siteId },
    onError: setError,
  });
  const whatsAppAccounts = secrets?.alistSecrets?.filter(s => s.type == SecretType.WhatsApp) || [];
  const { data: webhooksData, refetch: refetchWebhooks } = useQuery(LIST_WHATSAPP_WEBHOOKS, {
    variables: { flowId: appId! },
    onError: setError,
    skip: !appId,
  });
  const webhooks = webhooksData?.alistWhatsappWebhooks || [];
  const [addWhatsappWebhook] = useMutation(ADD_WHATSAPP_WEBHOOK);

  if (!appId) return <Typography>Please save the app first.</Typography>

  return <Stack spacing={2}>
    <Stack direction='row' display='flex' spacing={2} justifyContent='space-between'>
      <Typography variant='subtitle1'><b>Share link</b></Typography>
      <Button
        variant='outlined'
        sx={{ textTransform: 'none' }}
        onClick={() => {
          navigator.clipboard.writeText(sharingLink);
          setLinkCopied(true);
        }}
      >
        {linkCopied
          ? "Copied to clipboard"
          : 'Copy'}
      </Button>
    </Stack>
    <TextField variant='standard' value={sharingLink} />

    <Stack direction='row' display='flex' spacing={2} justifyContent='space-between'>
      <Typography variant='subtitle1'><b>Embed code</b></Typography>
      <Button
        variant='outlined'
        sx={{ textTransform: 'none' }}
        onClick={() => {
          navigator.clipboard.writeText(embedCode);
          setCodeCopied(true);
        }}
      >
        {codeCopied
          ? "Copied to clipboard"
          : 'Copy'}
      </Button>
    </Stack>
    <TextField variant='outlined' value={embedCode} multiline />

    <Stack direction='row' display='flex' spacing={2} justifyContent='space-between'>
      <Typography variant='subtitle1'><b>Integrate with WhatsApp</b></Typography>
      {!addingWhatsApp && <Button
        variant='outlined'
        sx={{ textTransform: 'none' }}
        onClick={() => setAddingWhatsApp(true)}
      >
        Add
      </Button>}
    </Stack>
    {webhooks.map(
      hook => <Stack key={hook.webhookId} direction='row' spacing={2} display='flex' justifyContent='space-between' alignItems='center'>
        <Typography>{hook.name}</Typography>
        <IconButton color='error'><DeleteOutlineRoundedIcon fontSize='small' /></IconButton>
      </Stack>
    )}
    {addingWhatsApp && (
      loading
        ? <LinearProgress />
        : <Stack direction='row' spacing={1} display='flex'>
          <Select
            variant='standard'
            sx={{ flexGrow: 1 }}
            value={whatsAppAccountId}
            onChange={e => { setWhatAppAccountId(e.target.value); }}
          >
            {whatsAppAccounts.map(account => {
              return <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>;
            })}
          </Select>
          <Stack direction='row'>
            <IconButton color='success' disabled={!whatsAppAccountId} onClick={() => {
              if (!whatsAppAccountId) return;
              addWhatsappWebhook({
                variables: { siteId: props.siteId, flowId: appId, secretId: whatsAppAccountId }
              })
                .then(res => {
                  if (res.errors) setError(res.errors[0]);
                  else {
                    setAddingWhatsApp(false);
                    refetchWebhooks();
                  }
                })
                .catch(setError);
            }}><CheckRoundedIcon fontSize='small' /></IconButton>
            <IconButton color='error' onClick={() => setAddingWhatsApp(false)}><CloseRoundedIcon fontSize='small' /></IconButton>
          </Stack>
        </Stack>
    )}
  </Stack>;
}
